import { LitElement, html } from 'lit-element';
import { specialsReplace } from '../utils/html';

class HomeFeatured extends LitElement {

    constructor () {
        super();

        this.events = [];
    }

    createRenderRoot () {
        /**
        * Render template without shadow DOM. Note that shadow DOM features like
        * encapsulated CSS and slots are unavailable.
        */
        return this;
    }

    static get properties() {
        return {
            events: { type: Array }
        };
    }

    render() {
        if ( !this.events ) return html``;

        return html`
            <div class="HomeFeatured">
                <h2 class="headline-xl featured-title">${ specialsReplace( window.copy.getClientInstance( 'home_featured' ) )}</h2>

                <div class="featured-container">
                    ${ this.events.map( ( event ) => html`<home-event .event=${ event } .featured=${ true }></home-event>` )  }
                </div>
            </div>
        `;
    }

}

customElements.define( 'home-featured', HomeFeatured );
